/*
SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen

SPDX-License-Identifier: CC0-1.0
*/

tei-head {
  display: block;
  font-size: 1.2em;
  text-align: center;
  font-weight: bold;
}
tei-q {
  font-weight: 700 !important;
}

tei-q tei-seg::before {
  content: '"';
}

tei-q tei-seg::after {
  content: '"';
}

TEI-p {
  display: block;
  margin-bottom: 1em; /* You can adjust this value based on your preference */
}
.highlight {
  background-color: yellow !important;
}

.bold-header {
    font-weight: 900 !important;
    color: red;
}
.single-annotation-highlight {
  background-color: #fffaa5; /* Light yellow */
  border-radius: 2px;
  padding: 0 2px;
}

.single-annotation-icon .material-icons-outlined {
  font-size: 1rem; /* or any size */
  line-height: 1;
}

